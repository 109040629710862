import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import CertifCards from "./CertifCards";

function transformFileName(filePath) {
  // Extract the filename from the file path
  const fileName = filePath.split("/").pop().replace(".pdf", "");
  
  // Remove unwanted characters using regex
  const cleanFileName = fileName.replace(/[^a-zA-Z0-9-]+.*$/, "");

  // Replace "-" with " " and capitalize each word
  return cleanFileName
    .split("-")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function importAll(r) {
  return r.keys().map(r);
}

const pdfFiles = importAll(require.context("../../Assets/certifs", false, /\.pdf$/));

function Certifications() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Mes <strong className="purple">Certifications </strong>
        </h1>
        <p style={{ color: "white" }}>
          Ici vous pouvez voir mes certifications
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {pdfFiles.map((file, index) => (
            <Col md={4} className="project-card">
              <CertifCards
                key={index}
                title={transformFileName(file)}
                pdfPath={file}
                ghLink="#"
                isBlog={false}
                demoLink="#"
              />
            </Col>
          ))}

        </Row>
      </Container>
    </Container>
  );
}

export default Certifications;
