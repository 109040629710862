import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Salut tout le monde, je suis <span className="purple">Wilfried HOUEDANOU </span>
            né à <span className="purple"> Cotonou, Bénin.</span>
            <br />
            Je suis actuellement en alternance à Enedis (entreprise) et Ynov Lyon (école d'ingénierie informatique).
            <br />
            En septembre 2024, je soutiens pour mon diplôme d'ingénieur en développement logiciel, mobile et Iot ainsi que pour mon titre RNCP niveau 7.
            <br />
            <br />
            À part coder, je suis passionné par :
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> La musique
            </li>
            <li className="about-activity">
              <ImPointRight /> Le cinéma
            </li>
            <li className="about-activity">
              <ImPointRight /> Le sport (Football, randonnée)
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
