import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
import { Document, Page } from "react-pdf";

function CertifCards(props) {
  return (
    <Card className="project-card-view">
      <Document file={props.pdfPath} className="pdf-preview">
        <Page pageNumber={1} width={390} />
      </Document>
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>

        {/* <Button variant="primary" href={props.ghLink} target="_blank">
          <BsGithub /> &nbsp;
          {props.isBlog ? "Blog" : "GitHub"}
        </Button>
        {"\n"} */}
        {"\n"}

        {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}

        
      </Card.Body>
    </Card>
  );
}

export default CertifCards;
